import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenUtil } from '../../core/services/TokenUtil.service';
import { ConnectionService } from '../../modules/organization/connection.service'
import { MixpanelService } from '../../shared/services/mixpanel.service';
import { MetaService } from './meta-service';
import { BoxInstance } from 'src/app/core/boxInstances/BoxInstance';
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';
import { v4 as uuid } from 'uuid';
import { BoxCache } from 'src/app/core/boxInstances/BoxCache';

@Injectable({
  providedIn: 'root'
})
export class BoxService {

  OBJECT_FUNCTIONS_CACHE = {}
  //display names properties
  attributeDisplayName: string;
  boxDisplayName: string;
  objectDisplayName: string;
  rowDisplayName: string;

  constructor(
    private TokenUtil: TokenUtil,
    private http: HttpClient,
    private orgService: ConnectionService,
    private connectionService: ConnectionService,
    public mps: MixpanelService,
    public metaService: MetaService,
    private httpCacheService: HttpCacheService
  ) { }

  async getAutorizationTokenHeader() {
    var result = { Authorization: "" };
    var token = await this.TokenUtil.getStatelessToken();
    result.Authorization = `Bearer ${token}`;
    return result;
  }

  async getBoxFunctions(boxId: string, boxConfigToken: string) {
    var options = { headers: { boxconfigToken: boxConfigToken, "Content-Type": "application/json" } }
    var tokenMap = await this.getAutorizationTokenHeader();
    options.headers = Object.assign(options.headers, tokenMap)
    let api = `/${boxId}/functions`
    let url = await BoxInstance.getInstance(api);
    try {
      // let response: any = await this.http.get(url + api, options).toPromise()
      let response: any = await this.httpCacheService.get(url + api, options, {"avoidAuthKey": true});
      console.log("functions fetched", response.functions)
      return response.functions
    } catch (err) {
      console.error("could not get box functions", err)
      return []
    }
  }

  /**
   * based on given params, this is able to get record of any type i.e any box, any boxObject
   * @param dataBindSetup an object containing
   * 1. boxId: string
   * 2. connectionId: string
   * 3. boxObject: string
   * 4. pageNumber: number
   * 5. pageSize: number
   * 6. getFnOptions?: any[],
   * 7. attributes?: any[],
   * 8. filters?: [] of {attributeId: string, value: any, dataType: string},
   * 9. sort?: [string] of the form `attrId=<order>[,attrId=<order>]` <order> can be ASC | DESC
   * @param mode [string] 'preauthenticated_token' | 'user_api_key'
   */
  async getAny(dataBindSetupParam: any, mode: string = 'preauthenticated_token', funOptions?: any) {
    let dataBindSetup = JSON.parse(JSON.stringify(dataBindSetupParam))
    console.log(`get ${dataBindSetup.boxObject} request will be sent. Config:`, dataBindSetup, "mode:", mode)

    // console.log("attributes to fetch", dataBindSetup.attributes)
    // console.log("this.connectionService.preAuthenticatedToken", this.connectionService.preAuthenticatedToken)

    let boxToken = dataBindSetup.boxConfigToken || "";
    let boxId = dataBindSetup.boxId;
    let payloadOptions;
    if(dataBindSetup.boxId == 'starch'){
      boxToken = dataBindSetup.boxConfigToken;
      boxId = dataBindSetup.baseMap?.box_id;
      if(dataBindSetup.boxId == 'starch'){
        payloadOptions = {
          relationObject: "starch_relationship"
        }
      }
    // } else if(!boxToken) {
    } else {
      let connData;
      if (mode == 'preauthenticated_token') {
        connData = await this.connectionService.getConnection(dataBindSetup.connectionId, this.connectionService.preAuthenticatedToken, 'preauthenticated_token')
      } else if (mode == 'user_api_key') {
        let userData = await this.metaService.getUser()
        let user_api_key = userData['user_api_key']
        connData = await this.connectionService.getConnection(dataBindSetup.connectionId, user_api_key, 'user_api_key')
      }
      boxToken = connData.box_token;
    }

    var options:any = { headers: { boxconfigToken: boxToken, "Content-Type": "application/json" } }

    var tokenMap = await this.getAutorizationTokenHeader();
    options.headers = Object.assign(options.headers, tokenMap)

    //form page parameter string
    let pageNumber = dataBindSetup?.pageNumber || 1
    let pageSize = dataBindSetup?.pageSize || 20
    let nextPageToken = dataBindSetup?.nextPageToken || undefined
    let limit = 1000
    let page = `${pageNumber}|${pageSize}|${limit}`
    if(nextPageToken && nextPageToken != "") {
      nextPageToken = encodeURIComponent(nextPageToken)
      page = page + `|${nextPageToken}`
    }

    var endpoint = `/${boxId}/${dataBindSetup.boxObject}/get`;
    let boxUrl = await BoxInstance.getInstance(endpoint);
    let payload = {
      parameters: {
        query: {
          "attributes": "",
          "page": `${page}`
        },
        options: {}
      }
    }
    // ---------- add sort ---------------
    if(dataBindSetup.sort){
      payload.parameters.query['sort'] = dataBindSetup.sort
    }

    // --------- attach filters ----------
    /**
     * dataBindSetup.filters maybe array type, old approach
     * dataBindSetup.filters maybe prepared filter string, new approach (as in dataBindSetup.sort)
     */
    if(typeof dataBindSetup.filters == "string"){
      payload.parameters.query['filter'] = dataBindSetup.filters
    }else{
      if(dataBindSetup.filters?.length){
        let filter: any = ''
        dataBindSetup.filters.forEach(f => {
          // console.log("dealing filter", f)
          if (f.value || f.operator == "!~" || f.operator == "~") {
            let attribute = f.parameter ? f.parameter : f.attribute ? f.attribute : f.attributeId;
            filter = filter + `${attribute}${f?.operator || "=" }${f.value || ''}`
            if(f.dataType && !['object','array'].includes(f.dataType)) filter = filter + `|${f.dataType || 'string'}`
            filter = filter + ','
          }
        });
        filter = filter ? filter.substring(0, filter.length - 1) : '';
        if (filter) {
          payload.parameters.query['filter'] = filter
        }
      }
    }

    let attributes: string = ''
    if (dataBindSetup.attributes) {
      for (let i = 0; i < dataBindSetup.attributes.length; i++) {
        attributes = attributes + "," + dataBindSetup.attributes[i]
      }
      attributes = attributes.substring(1, attributes.length)
      payload.parameters.query.attributes = attributes
    }
    // console.log("attributes added to payload", attributes)


    // --------------- attach aggregate  ----------------
    let aggregate: string = ''
    if (dataBindSetup.aggregate) {
      console.log("dataBindSetup.aggregate", dataBindSetup.aggregate)
      let operation = `${dataBindSetup.aggregate.operation}`;
      // aggregate = `${dataBindSetup.aggregate.aggregateBy}|${dataBindSetup.aggregate.operation}`

      let attribute = dataBindSetup.aggregate?.groupBy ? `${dataBindSetup.aggregate.groupBy.__id}|${dataBindSetup.aggregate.groupBy.dataType}` : null;
      if(attribute && dataBindSetup.aggregate.groupBy.format){
        attribute = attribute + `|format:${dataBindSetup.aggregate.groupBy.format}`;
      }

      let attributes = attribute ? `${attribute},` : '';
      payload.parameters.query['attributes'] = `${attributes}${dataBindSetup.aggregate.aggregateBy.__id} ${operation}|number|aggregate:${operation}`
      payload.parameters.query['group'] = `${attribute ? attribute : 'null'}`;
    }

    if (dataBindSetup.getFnOptions && dataBindSetup.getFnOptions.length) {
      dataBindSetup.getFnOptions.forEach(option => {
        payload.parameters.options[option.__id] = option.value
      });
    }

    // console.log("dataBindSetup.options", dataBindSetup)
    if (dataBindSetup.options && dataBindSetup.options.length) {
      dataBindSetup.options.forEach(option => {
        payload.parameters.options[option.__id] = option.value
      });
    }



    if(payloadOptions) Object.assign(payload.parameters.options, payloadOptions)
    if(dataBindSetup.options && !Array.isArray(dataBindSetup?.options) && Object.keys(dataBindSetup?.options).length) Object.assign(payload.parameters.options, dataBindSetup?.options)
    console.log("payload prepared", payload)
    console.log("box url", boxUrl)
    console.log("box endpoint", endpoint)
    console.log("options", options)
    try {
      let response: any;

      if(!options?.headers?.traceid && options?.headers) {
        options.headers.traceid = uuid();
        console.log("[BOX] execute traceid", options.headers?.traceid);
      }

      let cheOptions:any = {"avoidAuthKey": true, keyPrefix: "box_get_"};
      if(funOptions?.clearCache) cheOptions.clearCache = true;
      if(this.httpCacheService.getBoxMethodFromEndpoint(endpoint) == "get" ) {
        response = await this.httpCacheService.post(boxUrl + endpoint, payload, options, cheOptions);
      } else response = await this.http.post(boxUrl + endpoint, payload, options).toPromise();

      console.log("----------- GET ANY:",payload,  "response ---->>>", response);
      console.log("----------- GET ANY:", dataBindSetup);
      //append the result into the dataBindSetup and return to retain all other details
      let result = dataBindSetup
      result.data = response.result.data || []
      result.count = response.result.page.total || 1000
      result.page = response.result.page

      // result.count = response.data.result.page.size
      return result

    } catch (e) {
      console.error(`[BOX-HTTP] Error on get any for ${dataBindSetup.boxId}:`, e)
      throw e;
    }
  }

  /**
   * based on given params, it forms suitable query and search record of any type
   * i.e any box, any boxObject, any filter, any page size
   * @param boxId
   * @param boxObject
   * @param searchInputValues searchInputValues may be of the following type
   * {
   *  attribute_1_id: { value: , dataType: },
   *  attribute_2_id: { value: , dataType: },
   *  .
   *  .
   *  attribute_n_id: {...}
   * }
   * @param pageSize
   *
   */
  async search(
    boxId: any,
    connectionId: any,
    boxObjectId: any,
    searchInputValues: any,
    sortAttributes: any,
    pageNumber: any,
    pageSize: any,
    mode: string = 'preauthenticated_token',
    nextPageToken?: string,
    previousPageToken?: string,
    attributeOptions?: any[],
    attributes?: any[]
  ) {
    console.log("connection Id", connectionId)
    console.log(`search function hit boxId: ${boxId}, boxObjectId: ${boxObjectId}, searchInputValues: ${JSON.stringify(searchInputValues)}, pageSize: ${pageSize},`)

    let connData;
    if (mode == 'preauthenticated_token') {
      connData = await this.connectionService.getConnection(connectionId, this.connectionService.preAuthenticatedToken, 'preauthenticated_token')

    } else if (mode == 'user_api_key') {
      let userData = await this.metaService.getUser()
      let user_api_key = userData['user_api_key']
      connData = await this.connectionService.getConnection(connectionId, user_api_key, 'user_api_key')

    } else {
      console.log("[boxService: getAny()] mode parameter not recognized")
    }
    var options = { headers: { boxconfigToken: connData.box_token, "Content-Type": "application/json"  } }
    // var options = {headers: { boxconfigToken: NEW_BOX_TOKEN} }

    // var interactiontoken = `Event ${this.orgService.workSpaceId}:${this.orgService.userProfile._id}`
    // options.headers['interactiontoken'] = interactiontoken;

    // this.mps.track("box_execute_object_function", {
    //   box_id: boxId,
    //   object: boxObjectId,
    //   function: "get", //box object function
    //   method: "post" //http method
    // })

    var tokenMap = await this.getAutorizationTokenHeader();
    options.headers = Object.assign(options.headers, tokenMap)

    var endpoint = `/${boxId}/${boxObjectId}/get`;
    let boxUrl = await BoxInstance.getInstance(endpoint);

    //create the filter
    let filter: any = ''
    Object.keys(searchInputValues).forEach(f => {
      if (searchInputValues[f].value) {
        filter = filter + `${f}=${searchInputValues[f].value}|${searchInputValues[f].dataType},`
        //filter = filter + `${f}=${searchInputValues[f].value},`
      }
    });
    filter = filter ? filter.substring(0, filter.length - 1) : '';

    //create the sort
    let sort: string = ''
    if (sortAttributes.length) {
      sortAttributes.forEach(attr => {
        sort = sort + `${attr.__id}=ASC,`
      });
      sort = sort.substring(0, sort.length - 1);
    }
    // console.log("sort initialized", sort)

    //create page
    let page = `${pageNumber}|${pageSize}|1000`

    let payload = {
      parameters: {
        "query": {
          "attributes": "",
          "page": page
        },
        options: {}
      }
    }

    let attributesString: string = ''
    if (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        attributesString = attributesString + "," + attributes[i]
      }
      attributesString = attributesString.substring(1, attributes.length)
      payload.parameters.query.attributes = attributesString
    }

    if (attributeOptions && attributeOptions.length) {
      attributeOptions.forEach(option => {
        payload.parameters.options[option.__id] = option.value
      });
    }

    if (filter) {
      payload.parameters.query['filter'] = filter
    }
    if (sort) {
      payload.parameters.query['sort'] = sort
    }

    console.log("payload prepared", payload)
    console.log("box url", boxUrl)
    console.log("options", options)

    try {
      let response: any = await this.http.post(boxUrl + endpoint, payload, options).toPromise()
      console.log("HTTP RESPONSE -------- > ", response.result.data)
      let result: any = {}
      result.data = response.result.data
      result.totalCount = response.result.totalCount || 0
      return result

    } catch (e) {
      console.error(`[BOX-HTTP] Error on search query`, e)
      throw e;
    }
  }

  /**
   * fetches the box object functions
   * @param boxId
   * @param boxObjectId
   * @param connectionId
   * @returns array of box object funtions
   */
  async getBoxObjectFuntions(boxId: string, boxObjectId: string, key: string, contype: any = 'connection') {

    let box_token;
    if(contype == 'token'){
      box_token = key;
    } else {
      let connData = await this.connectionService.getConnection(key, this.connectionService.preAuthenticatedToken)
      box_token = connData.box_token;
    }
    var options = { headers: { boxconfigToken: box_token, "Content-Type": "application/json"  } }
    var tokenMap = await this.getAutorizationTokenHeader();
    options.headers = Object.assign(options.headers, tokenMap)

    let endpoint = `/${boxId}/${boxObjectId}/functions`;
    let url = await BoxInstance.getInstance(endpoint);
    console.log("url", url)
    console.log("headers", options)
    try {
      // let res: any = await this.http.get(url + endpoint, options).toPromise()
      let res: any = await this.httpCacheService.get(url + endpoint, options, {"avoidAuthKey": true});
      this.OBJECT_FUNCTIONS_CACHE[key] = res.functions
      // console.log("box object functions fetched", res)
      return res.functions
    } catch (e) {
      console.log("could not fetch box object functions", e)
    }
  }

  /**
   * based on given params, this is able to create record of any type
   * @param boxId
   * @param boxObject
   * @param body the record object that is to be inserted in db
   */
  async createAny(boxId: any, boxObject: any, body: any) {
    console.log("create any hit: boxId:", boxId, "boxObject:", boxObject, "boxObjectFunction: create")
    console.log("body:", body)

    var options = { headers: { boxconfigToken: environment.DB_BOX_TOKEN, "Content-Type": "application/json" } }
    var tokenMap = await this.getAutorizationTokenHeader();
    options.headers = Object.assign(options.headers, tokenMap)

    let endpoint = `/${boxId}/${boxObject}/create`;
    let url = await BoxInstance.getInstance(endpoint);
    console.log("url to hit:", url)

    let payload = {
      parameters: {
        data: [
          body
        ]
      }
    }

    try {
      let response: any = await this.http.post(url + endpoint, payload, options);
      console.log("create any response ---->", response);
      return response.data
    } catch (e) {
      console.error("[BOX-HTTP] Error on create:", e)
      throw e;
    }
  }


  /**
   * fetches the box objects for a given box id and boxConfigToken
   * uses TokenUtil service for stateless token, and gets url from environment
   * @param boxId
   * @param boxConfigToken
   * @returns boxObjects: any[]
   */
  async getBoxObjects(key: string, contype:any = 'connection', options?: any, isUseCache: boolean = true) {

    let box_token;
    let box_id;
    if(contype == 'token'){
      box_token = key;
      box_id = options.box_id;
    } else {
      let connData = await this.connectionService.getConnection(key, this.connectionService.preAuthenticatedToken)
      box_token = connData.box_token;
      box_id = connData.box_id;
    }


    let boxObjects: any;
    let token = await this.TokenUtil.getStatelessToken()
    var httpOptions = { headers:
      { boxconfigToken: box_token,
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
      }
    }

    // console.log("options", JSON.parse(JSON.stringify(options || { isNull: true})))
    // console.log("tokenMap", JSON.parse(JSON.stringify(tokenMap || {isNull: true})))
    // var tokenMap = await this.getAutorizationTokenHeader();
    // console.log("tokenMap", tokenMap)
    // options.headers = Object.assign(options.headers, tokenMap)

    var endpoint = `/${box_id}/objects`;
    let url = await BoxInstance.getInstance(endpoint);
    console.log("sending getobjects for", box_id,"with headers", httpOptions)
    // console.log("hitting url", url)

    try{
      console.log("calling", url, "with get method")
      var response: any;
      // Use cache service if useCache is true, otherwise direct GET request
      if (isUseCache) {
        response = await this.httpCacheService.get(url + endpoint, httpOptions, {"avoidAuthKey": true});
      } else {
        response = await this.http.get(url + endpoint, httpOptions).toPromise();
      }
      // let response: any = await this.http.get(url + endpoint, httpOptions).toPromise();
      // let response: any = await this.httpCacheService.get(url + endpoint, httpOptions, {"avoidAuthKey": true});
      // console.log('objects response in boxService: getBoxObjects()', response);
      let res = response?.objects || [];
      return res;
    }catch(e){
      console.error('Error in boxService: getBoxObjects()', e);
      throw e
    }
  }

  async getAttributes(key: string, boxId: string, boxObjectId: string, attributeOptions?: any, mode?:any, contype:any = 'connection', payloadOptions?:any) {
    console.log("boxId", boxId)
    console.log("boxObject", boxObjectId)
    console.log("connectionId", key)
    console.log("payloadOptions", payloadOptions)

    let boxConfigToken;
    let box_id;
    if(contype == 'token'){
      boxConfigToken = key;
    } else {
      let connData;
      if (mode == 'user_api_key') {
        let userData = await this.metaService.getUser()
        console.log("publisher data in getAny", userData)
        let user_api_key = userData['user_api_key']
        console.log("user api key", user_api_key)
        connData = await this.connectionService.getConnection(key, user_api_key, 'user_api_key')
      } else {
        let preAuthToken = await this.connectionService.getPreAuthenticatedToken()
        connData = await this.connectionService.getConnection(key, preAuthToken)
      }
      boxConfigToken = connData.box_token;
    }

    let payload = {
      parameters: {
        object: boxObjectId,
        options: {}
      }
    }

    if(boxId == 'starch'){
      payload.parameters.options['relationObject'] = "starch_relationship"
    }

    if (attributeOptions && attributeOptions.length) {
      attributeOptions.forEach(option => {
        payload.parameters.options[option.__id] = option.value
      });
    }

    if(payloadOptions) Object.assign(payload.parameters.options, payloadOptions)

    console.log("get attributes payload", payload)

    let token = this.TokenUtil.getStatelessToken()
    // let headers = await new HttpHeaders()
    //   .set('Authorization', `Bearer ${token}`)
    //   .set('boxConfigToken', boxConfigToken);

    var options = { headers: { boxconfigToken: boxConfigToken, Authorization : `Bearer ${token}`, "Content-Type": "application/json"  } }
    let endpoint = `/${boxId}/getattributes`;
    let url = await BoxInstance.getInstance(endpoint);

    console.log("get attributes url", url)
    console.log("get attributes headers", options)

    try{
      let response = await this.http.post(url + endpoint, payload, options).toPromise();
      console.log('Attributes received', response);

      if(response){
        response['connectionId'] =  contype != 'token' ? key : null;
        response['boxId'] = boxId
        response['boxObjectId'] = boxObjectId
        return response;
      }

    } catch(e){
      console.log('Error while getting attributes', e);
      throw e
    }


    // return new Promise((resolve, reject) => {
    //   this.http
    //     .post(url, payload, { headers: headers })
    //     .subscribe(
    //       (response: any) => {
    //         if (!response) {
    //           console.log("invalid no response received")
    //           return
    //         }
    //         response['connectionId'] =  contype != 'token' ? key : null;
    //         response['boxId'] = boxId
    //         response['boxObjectId'] = boxObjectId
    //         console.log('Attributes received', response);
    //         resolve(response)
    //       },
    //       (error) => {
    //         console.log('Error while getting attributes', error);
    //         reject(error)
    //       }
    //     );
    // })
  }

  async updateRecord(connectionId: string, boxId: string, boxObjectId: string, data: any){
    console.log(`update fn hit with connectionId ${connectionId}, boxId: ${boxId}, boxObjectId: ${boxObjectId}`)
    console.log("data", data)

    let connData = await this.connectionService.getConnection(connectionId, this.connectionService.preAuthenticatedToken)
    if (!connData) {
      console.log("could not fetch connection (boxConfigToken not retrived)")
      return []
    }

    var options = { headers: { boxconfigToken: connData.box_token, "Content-Type": "application/json"  } }
    var tokenMap = await this.getAutorizationTokenHeader();
    options.headers = Object.assign(options.headers, tokenMap)

    let endpoint = `/${boxId}/${boxObjectId}/update`;
    let url = await BoxInstance.getInstance(endpoint);

    let payload = {
      parameters: {
        data: [
          data
        ]
      }
    }
    console.log("upload url", url)
    console.log("update headers", options)
    console.log("update payload", payload)
    try {
      let response: any = await this.http.post(url + endpoint, payload, options).toPromise();
      console.log("update any response ---->", response);
      return response.data
    } catch (e) {
      console.error("[BOX-HTTP] Error on update:", e)
      throw e;
    }
  }

  async getUpdateFuntion(connectionId: string, boxId: string, boxObjectId: string){
    let updateFn: any
    if(
      this.OBJECT_FUNCTIONS_CACHE[connectionId] &&
      this.OBJECT_FUNCTIONS_CACHE[connectionId].length &&
      this.OBJECT_FUNCTIONS_CACHE[connectionId].find(fn => fn.__id == 'update') > -1
    ){
      console.log("update fn found in cache")
      return true
    }else{
      console.log("will fetch update fn")
      let functions
      try{
        functions = await this.getBoxObjectFuntions(boxId, boxObjectId, connectionId)
        console.log("box object funtions fetched", functions)
        this.OBJECT_FUNCTIONS_CACHE[connectionId] = functions

        updateFn = functions.find(fn => fn.__id == 'update')
      }catch(e){
        console.log("could not fetch box object functions", e)
        throw e
      }
      console.log("update function received", updateFn)
      return updateFn ? true : false
    }
  }

  async execute(url:string, body: any, options: any){
    try {
        if(!options?.headers?.traceid) {
          options.headers.traceid = uuid();
          console.log("[BOX] execute traceid", options.headers?.traceid);
        }
        let response: any = await this.http.post(url, body, options).toPromise();
        console.log("response ---->", response);
        return response.data;
    } catch(e){
        console.error("[BOX-HTTP] Error on create:", e)
        throw e;
    }
  }

  setBoxObjectDisplayNames(boxId: string) {
    const boxObjectMap = BoxCache.getCacheMap();
    const boxObjectDisplayObj =  boxObjectMap?.[boxId]?.displayName;

    this.attributeDisplayName = boxObjectDisplayObj?.attribute || "field";
    this.boxDisplayName = boxObjectDisplayObj?.box || "application";
    this.objectDisplayName = boxObjectDisplayObj?.object || "object";
    this.rowDisplayName = boxObjectDisplayObj?.row || "record";
  }

}
