import { environment } from '../../../environments/environment';
import {
  Inject,
  Injectable,
  NgZone,
} from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class UIConfigService {

  private defaultMenuOptions = ["products", "integrations", "solutions", "pricing", "resource"];
  public menuOptions: string[] = [];

  // Default menu options to display across different UI components
  private defaultProductMenuOptions = ["connection", "bloom", "flow", "form", "starch", "home"];
  public productMenuOptions: string[] = [];

  private betaProducts = ['bloom'];
   // Configuration for showing beta tag across the app
  public showBetaTag: boolean = false;
  private isBetaTagManuallySet: boolean = false; // Tracks if showBetaTag was manually set

  public subProduct: any;

  betaProductOptions: any;
  defaultBetaProductOptions: any  = ['bloom', "flow"];;

  constructor() {
    // Initialize with default values
    this.productMenuOptions = [...this.defaultProductMenuOptions];
    this.menuOptions = [...this.defaultMenuOptions];
    this.betaProductOptions = [...this.defaultBetaProductOptions];
  }

  /**
   * Menu manager function for managing product menu options
   * @param action - Action to perform: 'get', 'set', or 'reset'
   * @param options - Optional array of menu options to set
   * @returns The current list of menu options if 'get' is specified
   */
  public manageMenu(action: 'get' | 'set' | 'reset', options?: string[]):any {
    switch (action) {
      case 'get':
        return this.menuOptions;

      case 'set':
        if (options && options.length > 0) {
          this.menuOptions = options.filter(option => this.defaultMenuOptions.includes(option));
        } else {
          console.warn("No options provided for 'set'. Using default menu options.");
          this.menuOptions = [...this.defaultMenuOptions];
        }
        break;

      case 'reset':
        this.menuOptions = [...this.defaultProductMenuOptions];
        break;

      default:
        console.error("Invalid action specified for manageMenu.");
    }
  }

   /**
   * Product menu manager function for managing product menu options
   * @param action - Action to perform: 'get', 'set', or 'reset'
   * @param options - Optional array of menu options to set
   * @returns The current list of menu options if 'get' is specified
   */
   public manageProductMenu(action: 'get' | 'set' | 'reset', options?: string[]):any {
    switch (action) {
      case 'get':
        return this.productMenuOptions;

      case 'set':
        if (options && options.length > 0) {
          this.productMenuOptions = options.filter(option => this.defaultProductMenuOptions.includes(option));
        } else {
          console.warn("No options provided for 'set'. Using default menu options.");
          this.productMenuOptions = [...this.defaultProductMenuOptions];
        }
        break;

      case 'reset':
        this.productMenuOptions = [...this.defaultProductMenuOptions];
        break;

      default:
        console.error("Invalid action specified for manageMenu.");
    }
  }

  /**
   * Product beta manager function for managing product menu options
   * @param action - Action to perform: 'get', 'set', or 'reset'
   * @param options - Optional array of menu options to set
   * @returns The current list of menu options if 'get' is specified
   */
  public manageBetaProduct(action: 'get' | 'set' | 'reset', options?: string[]):any {
    switch (action) {
      case 'get':
        return this.betaProductOptions;

      case 'set':
        if (options && Array.isArray(options)) {
          this.betaProductOptions = options.filter(option => this.defaultBetaProductOptions.includes(option));
        } else {
          console.warn("No options provided for 'set'. Using default menu options.");
          this.betaProductOptions = [...this.defaultBetaProductOptions];
        }
        break;

      case 'reset':
        this.betaProductOptions = [...this.defaultBetaProductOptions];
        break;

      default:
        console.error("Invalid action specified for manageMenu.");
    }
  }


  // method to manage beta tag visibility
  public manageBetaTag(action: 'get' | 'set' | 'reset', value?: boolean): any {
    switch (action) {
      case 'get':
        // If manually set, use the overridden showBetaTag value
        if (this.isBetaTagManuallySet) {
          return this.showBetaTag;
        }
        // Otherwise, determine based on subProduct presence in betaProducts
        return this.subProduct ? this.betaProducts.includes(this.subProduct) : false;

      case 'set':
        this.showBetaTag = value ?? false;
        this.isBetaTagManuallySet = true;
        break;
      case 'reset':
         // Reset to default automatic behavior
        this.isBetaTagManuallySet = false;
        // Recalculate showBetaTag based on subProduct
        this.showBetaTag = this.subProduct ? this.betaProducts.includes(this.subProduct) : false;
      default:
        console.error("Invalid action specified for manageBetaTag.");
    }
  }

}
