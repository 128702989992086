<div *ngIf="authService.loggedIn" class="object-explorer-wrap">
    <div style="height: .5rem;"></div>
    <div class="header-wrap" fxLayoutAlign="center center">
        <div class="section top-action-wrap" fxLayoutAlign="center center">
            <a mat-raised-button extended (click)="goBack()">Back<mat-icon>west</mat-icon></a>
        </div>
        <div class="section title-wrap" fxLayoutAlign="center center">
            <div class="title-text">Object Explorer</div>
        </div>
    </div>
    <div style="height: 1rem;"></div>
    <connection-info-header *ngIf="box && (connection || base) && object" [box]="box" [connection]="connection" [starch]="base" [objectId]="object.name"></connection-info-header>
    <div style="height: 1rem;"></div>

    <!-- <div class="section breadcrumb-wrap"></div> -->

    <!-- <div
        class="section object-actions-wrap"
        *ngIf="objectActions.length"
        fxLayout="row wrap" fxLayoutAlign="start center"
    >
        <button
            mat-raised-button
            color="primary"
            (click)="executeAction(action)"
            *ngFor="let action of objectActions"
        >
            {{action.name}}
        </button>
    </div> -->


    <!-- <div fxLayout="row" fxLayoutAlign="start center" style="margin-left: 1rem;" *ngIf="node.objectData">
        <button mat-stroked-button color="primary" class="smallButton" mat-icon-button matTooltip = "Add Record"
            (click)="createNewRecord(node)">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span><mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center">add_circle_outline</mat-icon></span>
                    <span>Add Record</span>
                </div>
        </button>
        <mat-spinner *ngIf="customAttributeSpinner" diameter="20" color="primary" style="margin-left: 2px;"></mat-spinner>
    </div> -->
    <div fxLayoutAlign="end center">
        <button style="margin: .3rem .5rem 0 .3rem;" color="primary" mat-stroked-button (click)="publishAction('list')">
            <mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center">publish</mat-icon>
            Publish as list
          </button>
          <button style="margin: .3rem .5rem 0 .3rem;" color="primary" mat-stroked-button (click)="publishAction('form')">
           <mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center">publish</mat-icon>
           Create a Form
           </button>
    </div>
    <div style="height: 1rem;"></div>
    <div class="section body-wrap" fxLayoutAlisgn="center start">
        <div class="body-item content-section" [fxFlex]="showPublishedViewsList || showPublishedViewsFrom ? '75' : '100'">
            <mat-tab-group animationDuration="1000ms" mat-align-tabs="center" (selectedTabChange)="tabChanged($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon style="margin-right: 1rem;">schema</mat-icon>
                        Schema
                    </ng-template>
                    <spinner [center]="true" [selfSpin]="spinner" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

                    <button
                        *ngIf="customAttributeSupported && attributes.length"
                        mat-stroked-button color="primary"
                        matTooltip = "Create Attribute"
                        (click)="createCustomAttribute()"
                        style="margin: .3rem .5rem 0 .3rem;"
                    >
                        <mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center">edit_note</mat-icon>
                        Create Attribute
                    </button>

                    <div>
                        <app-list-panel
                            *ngIf="attributes.length"
                            [panelMeta]="attributePanelMeta"
                            [inputData]="attributes"
                            [builderMode]="false"
                        ></app-list-panel>
                        <!-- (rowData)="selectedAttribute($event, object)" -->
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon style="margin-right: 1rem;">table_view</mat-icon>
                        Data
                    </ng-template>

                    <div *ngIf="dataListMeta" style="min-height: 20rem;">
                        <app-list-panel
                            [builderMode]="false"
                            [panelMeta]="dataListMeta"
                            (rowData)="rowSelected($event)"
                        >
                        </app-list-panel>
                        <!-- (rowData)="selectedRowData($event, node)"
                        (filterObj)="selectedFilter($event)" -->
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon style="margin-right: 1rem;">outbound</mat-icon>
                        Actions
                    </ng-template>
                    <spinner [center]="true" [selfSpin]="spinner" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

                    <div class="section">
                        <mat-chip-set *ngIf="objectActions.length">
                            <mat-chip
                              class="chip-item"
                              *ngFor="let action of objectActions"
                              [matTooltip]="'Execute ' + action.name"
                              (click)="executeAction(action)"
                            >
                              <div fxLayout="row">
                                <span class="button-text">{{ action.name }}</span>
                                <mat-icon>chevron_right</mat-icon>
                              </div>
                            </mat-chip>
                        </mat-chip-set>
                    </div>

                    <mat-accordion *ngIf="actionResults.length" class="accordion example-headers-align">
                        <mat-expansion-panel
                          *ngFor="let actionresult of actionResults; let i = index"
                          [expanded]="openResultIndex"
                          (opened)="openResultIndex = i"
                        >
                          <mat-expansion-panel-header>
                            <mat-panel-title>{{ actionresult.action.name + " result" }}</mat-panel-title>
                            <mat-panel-description>
                              <mat-icon matTooltip="Discard result" (click)="deleteResult(i)">delete</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                          <p>
                            <app-list-panel
                              [inputData]="actionresult.result"
                              [builderMode]="false"
                              [panelMeta]="{ listPanelTitle: actionresult.action.name + ' result' }"
                            >
                            </app-list-panel>
                          </p>
                        </mat-expansion-panel>
                      </mat-accordion>
                </mat-tab>
            </mat-tab-group>
        </div>

        <div class="body-item right-section" fxLayout="column" fxLayoutAlign="start center" [fxFlex]="showPublishedViewsList || showPublishedViewsFrom ? '25' : '0'" fxLayoutGap=".5rem">

            <!-- <button mat-raised-button class="action-button-big" (click)="publishAction('list')"><span class="action-button-big-text">Publish as list</span></button> -->
            <!-- <button disabled mat-raised-button class="action-button-big" (click)="publishAction('form')"><span class="action-button-big-text">Create a form</span></button> -->

            <div class="published-views-wrap" *ngIf="showPublishedViewsList" fxLayoutAlign="flex-start center" fxLayout="column">
                <div class="title-text" style="font-size: 1.3rem; color: #444; text-align: center; margin: .5rem 0;">Published Lists</div>
                <div
                    *ngFor="let list of connection.published_views[objectId].published_lists.list_ids"
                    fxFlex="95" fxLayoutAlign="center center"
                    class="published-view-item"
                >
                    <div fxFlex="100" style="font-size: .9rem; color: #777;" fxLayoutAlign="space-between center">
                        <div
                            fxFlex="80"
                            [matTooltip]="connection.published_views[objectId].published_lists[list].name"
                            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                        >
                            {{connection.published_views[objectId].published_lists[list].name}}
                        </div>
                        <button fxFlex="20" mat-icon-button matTooltip="Open in new" (click)="openView(connection.published_views[objectId].published_lists[list])">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="published-views-wrap" *ngIf="showPublishedViewsFrom" fxLayoutAlign="flex-start center" fxLayout="column">
              <div class="title-text" style="font-size: 1.3rem; color: #444; text-align: center; margin: .5rem 0;">Published Forms</div>
              <div
                  *ngFor="let list of connection.published_views[objectId].published_forms.list_ids"
                  fxFlex="95" fxLayoutAlign="center center"
                  class="published-view-item"
              >
                  <div fxFlex="100" style="font-size: .9rem; color: #777;" fxLayoutAlign="space-between center">
                      <div
                          fxFlex="80"
                          [matTooltip]="connection.published_views[objectId].published_forms[list].name"
                          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                      >
                          {{connection.published_views[objectId].published_forms[list].name}}
                      </div>
                      <button fxFlex="20" mat-icon-button matTooltip="Open in new" (click)="openView(connection.published_views[objectId].published_forms[list])">
                          <mat-icon>open_in_new</mat-icon>
                      </button>
                  </div>
              </div>
          </div>
        </div>
    </div>
    <div class="auth-error" *ngIf="authError">
        <mat-card appearance="outlined">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem" style="width: 100%;">
            <div style="text-align: center; width: 100%;">Workspace not loaded. Try signing in.</div>
            <button mat-raised-button class="signin-button" [routerLink]="['/']">
                Sign in
            </button>
          </div>
        </mat-card>
    </div>
</div>
<!-- <h3>{{ "object:" + objectId }}</h3> -->
