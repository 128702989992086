import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { BoxService } from '../services/box.service';
import { WidgetRegistry } from 'src/app/bloom/models/WidgetRegistry';
import { COMMA, E, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import {CdkDrag, CdkDragDrop, CdkDragPlaceholder, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';

interface ComponentsEnablementMap {
  filter?: boolean,
  sort?: boolean,
  attributes?: boolean,
  group?: boolean,
  limit: boolean
}
interface QueryInputs {
  subComponentsToShow: ComponentsEnablementMap,
  filterConfig?: any,
  sortConfig?: any,
  groupConfig?: any,
  attributesConfig?: any,
  limit?: number,
  retainSubComponentOutput?: boolean
  attributeInputOptions?:any
  recommendAttributes?:boolean
}

@Component({
  selector: 'shared-parameter-inputs',
  templateUrl: './parameter-inputs.component.html',
  styleUrls: ['./parameter-inputs.component.scss']
})
export class ParameterInputsComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  groupAttriCtrl = new UntypedFormControl('');
  attributesForGroup: any;
  dependencyMap = {
    list:[]
  };
  parametersReceived: boolean = false;
  fieldMap: any;

  constructor(
    public con: ConnectionService,
    private dialog: MatDialog,
    public boxService: BoxService,
    private spin: SpinnerService
  ) { }

  @Input() action: any;
  @Input() allActions: any;
  @Input() connection:any;
  @Input() existingInputs:any;
  @Input() dependancyMapObj:any;
  @Input() optionalInputs: any;
  @Input() getAttributesFunction:any;
  @Input() attributesFetched:any;
  @Input() starchBase:any;
  @Input() parameters:any

  @Input() isSupportQuery: boolean;
  @Input() queryInputs: QueryInputs;

  @Input() isSupportGroup: boolean;
  @Input() isSupportFilter: boolean;
  @Input() isSupportCountLimit: boolean;
  @Input() isOnlyOptions: boolean;
  @Input() isBoxObject?: boolean;
  @Input() fieldListingMap: any;

  @Output() "inputParameter" = new EventEmitter<any>();
  @Output() "enableExecute" = new EventEmitter<any>();
  @Output() "getAttributesResponse" = new EventEmitter<any>();

  spinner: boolean = false;
  firsthit: boolean = false;
  valueOptionsObj : any = {}
  boxObject:any;
  boxObjectAttributes: any;
  parameterWidgets:any = [];
  attributesParameterWidgets:any = [];
  mandatoryInputs = [];
  attributeInputMap: any = {};
  buildAttributeParams: boolean = false;
  show: boolean = true;
  attributeHide: boolean = true;
  inputMap: any = { };
  selectedInputOption: any = {};
  filter: any = {
    filterEnabled: false,
    items: []
  }

  group:any = {
    groupEnabled: false,
    attributes: []
  }

  customQuery: any = {
    filter: ""
  }

  selectFilterAttributeForm = new UntypedFormGroup({});
  attributesForFilter: any;
  availableOperators: any[] = [
    { name: '> Greater Than', value: '>'},
    { name: '> Smaller Than', value: '<'},
    { name: '= Equal', value: '='},
    { name: '!= Not Equal', value: '!='},
  ]
  arrayParamWidgets: any[] = []

  async doInit(){
    var isParamsRequired: boolean = true;
    this.spinner = true
    console.log("[PARAMETER-INTPUTS] fieldListingMap", this.fieldListingMap)
    console.log("[PARAMETER-INTPUTS] dependancyMapObj", this.dependancyMapObj)
    console.log("[PARAMETER-INTPUTS] init action", this.action);
    console.log("[PARAMETER-INTPUTS] init All actions", this.allActions);
    console.log("[PARAMETER-INTPUTS] init isSupportQuery", this.isSupportQuery);
    console.log("[PARAMETER-INTPUTS] init queryInputs", this.queryInputs);
    console.log("[PARAMETER-INTPUTS] init connection", this.connection);
    console.log("[PARAMETER-INTPUTS] init existing inputs", JSON.parse(JSON.stringify(this.existingInputs || "")))
    console.log("optional inputs", this.optionalInputs)
    let existingInputs = JSON.parse(JSON.stringify(this.existingInputs || ""))

    if(!this.action?.__id && this.action?.list?.length){
      this.action = {
        input: this.action
      }
    }

    //if connection id is passed insteadof connection object get connection object.
    if (typeof this.connection == 'string') {
      this.connection = await this.con.getConnection(this.connection);
    }

    console.log("this.action)", this.action)
    if(this.action?.__id?.split("/").length > 1 || this.optionalInputs?.options?.object){
      this.boxObject = this.optionalInputs?.options?.object ? this.optionalInputs?.options?.object : this.action.__id.split("/")[0];
      if(this.optionalInputs) this.optionalInputs['object'] = this.boxObject
      console.log("box object", this.boxObject)
      console.log("INPUTS FOR GET ATTRIBUTES", this.getAttributesFunction?.input?.options?.list);
      console.log("isSupportQuery ",this.isSupportQuery)

      if(this.getAttributesFunction?.input?.options?.list?.length){
        let count = 0
        let inputlist = this.getAttributesFunction?.input?.options?.list
        for(let i = 0; i < inputlist.length; i++){
          let inputlistAttMap = this.getAttributesFunction?.input?.options?.[inputlist[i]];
          console.log("listAttrMap : ", inputlistAttMap)
          if(inputlistAttMap?.hidden == true) count++;
        }
        if(count == inputlist.length) isParamsRequired = false;
      }

      if(this.getAttributesFunction?.input?.options?.list?.length && isParamsRequired) {
        //populate this.boxObjectAttributes by calling boxService.getAttributes. before that construct parameter for getAttributes
        this.buildAttributeParams = true;
        this.show = false;
        this.enableExecute.emit(false);
        this.firsthit = true
        await this.constructParams();
        this.buildAttributeParams = false;
      }
      else if(this.isSupportQuery){
        console.log("else part, will fetch attributes")
        let inputOptions = {};
        if(this.queryInputs?.attributeInputOptions) inputOptions = this.queryInputs.attributeInputOptions;
        console.log("inputOptions", inputOptions)

        let boxId = this.connection?.box_id || null;
        let connectionId = this.connection?._id || null;
        let boxToken = null
        let actionId = this.action.__id;
        if(this.starchBase){
          boxId = this.starchBase.box_id;
          boxToken = this.connection;
        }
        this.boxObjectAttributes = this.attributesFetched?.length ? this.attributesFetched : await this.boxService.getAttributes(boxId, actionId, connectionId, boxToken, inputOptions, this.optionalInputs?.options?.object)
        await this.checkDependancy(this.action, this.boxObjectAttributes)
        for (var i=0; i < this.dependencyMap.list.length-1; i++){
          this.dependencyMap[this.dependencyMap.list[i]].requiredInput = this.dependencyMap.list.slice(i+1)
        }
        this.dependencyMap.list = this.dependencyMap.list.reverse()
      }
      console.log("attributes in box object attri", this.boxObjectAttributes)
      this.getAttributesResponse.emit(this.boxObjectAttributes)
    }

    if(this.existingInputs?.filterConfig) this.generateExistingFilterItems()
    if(existingInputs?.groupConfig) this.group = existingInputs?.groupConfig;
    if(!this.parameters) {
      if(!this.boxObjectAttributes && this.allActions){
        let boxId = this.connection?.box_id || null;
        let connectionId = this.connection?._id || null;
        let boxToken = null
        let actionId = this.action.__id;
        if(this.starchBase){
          boxId = this.starchBase.box_id;
          boxToken = this.connection;
        }
        let inputOptions = {};
        if(this.queryInputs?.attributeInputOptions) inputOptions = this.queryInputs.attributeInputOptions;
        console.log("inputOptions", inputOptions)
        this.boxObjectAttributes = this.attributesFetched?.length ? this.attributesFetched : await this.boxService.getAttributes(boxId, actionId, connectionId, boxToken, inputOptions, this.optionalInputs?.options?.object)
        await this.checkDependancy(this.action, this.boxObjectAttributes)
        for (var i=0; i < this.dependencyMap.list.length-1; i++){
          this.dependencyMap[this.dependencyMap.list[i]].requiredInput = this.dependencyMap.list.slice(i+1)
        }
        this.dependencyMap.list = this.dependencyMap.list.reverse()
      }
      this.firsthit = true
      this.constructParams();
    }
    if(this.parameters) this.constructInputParams();

    this.constructFieldMap()
    this.spinner = false
  }

  async ngOnInit() {
    if(this.parameters?.length > 0) this.parametersReceived = true;
    this.doInit()
  }
  async ngOnChanges(changes: any){
    // console.log("changes", changes)
    if(this.parametersReceived && changes?.parameters &&  !changes?.parameters?.firstChange) {
        this.doInit();
      }
  }

  insertFilterCursor(e, filterMap){
    let value = e.__id;
    let cursorValue = '${' + value + '}';
    filterMap.value = cursorValue;
  }

  insertCursor(e, param){
    let value = e.__id;
    let newValue: any;
    let cursorValue = '${' + value + '}';
    let newWidget: any = WidgetManager.getWidget(param?.widget.type)
    //set new value based on key
    if (param.firstKey) {
      newValue = this.inputMap[param.firstKey][param.key] + cursorValue;
    } else {
      newValue = this.inputMap[param.key] + cursorValue;
    }
    //create new widget using existing widget.
    Object.keys(param.widget).forEach(key => {
      newWidget[key] = param.widget[key]
    });
    newWidget.setValue(newValue, false);
    //assign new widget config to existing param widget.
    param.widget = newWidget;
    console.log("Insert cursor:= event: %s, param %s, newValue %s", e, param, newValue)

    if(param.firstKey){
      if(!this.inputMap[param.firstKey]) this.inputMap[param.firstKey] = {};
      if(!this.selectedInputOption[param.firstKey]) this.selectedInputOption[param.firstKey] = {};
      this.inputMap[param.firstKey][param.key] =cursorValue;
      this.selectedInputOption[param.firstKey][param.key] =cursorValue;
    } else {
      this.inputMap[param.key] += cursorValue;
      this.selectedInputOption[param.key] += cursorValue;
    }

    // if(param?.widget?.type == 'query' && event.subComponentOutput){
    //   for (const key in event.subComponentOutput) {
    //     this.inputMap[key] = event.subComponentOutput[key]
    //   }
    //   // this.inputMap.__limit = this.inputMap?.query?.page?.split('|')[1] || this.inputMap.__limit
    // }

    console.log("this.inputMap", JSON.parse(JSON.stringify(this.inputMap)));
    this.inputParameter.emit(this.inputMap);
  }

  generateExistingGroupItems(){
    this.group = this.existingInputs?.groupConfig;

  }

  generateExistingFilterItems(){
    this.filter = this.existingInputs?.filterConfig;
    if(this.filter.filterItems && this.filter.filterItems.length > 0) {
      for(var i = 0; i < this.filter.filterItems.length; i ++){
        this.selectFilterAttributeForm.addControl("form" + i, new UntypedFormControl());
        let valueMap = {
          __id: this.filter.filterItems[i].attribute,
          dataType: this.filter.filterItems[i].dataType || "string"
        }
        this.selectFilterAttributeForm.patchValue({[`form${i}`]: valueMap});
      }
    }
  }

  removeFilter(i: number){
    this.filter.filterItems.splice(i, 1)
  }

  addFilterItem(){
    if(!this.filter.filterItems) this.filter.filterItems = [];
    this.filter.filterItems.push({
      attribute: '',
      operator: '=',
      value: '',
      dataType: '',
      filterType: 'static_filter'
    })
    this.selectFilterAttributeForm.addControl("form" + (this.filter.filterItems.length - 1), new UntypedFormControl())
  }

  setFilterList(i){
    // FILTER FOR LIST PANEL FILTERS
    this.attributesForFilter = this.selectFilterAttributeForm.get(`form${i}`).valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._filterAttributesFilter(value))
    );
  }

  addGroup(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.group.attributes.push(value);
    }
    // Clear the input value
    event.chipInput!.clear();

    this.groupAttriCtrl.setValue(null);
  }

  removeGroup(fruit: string): void {
    const index = this.group.attributes.indexOf(fruit);
    if (index >= 0) {
      this.group.attributes.splice(index, 1);
    }
  }

  selectedGroup(event: any): void {
    this.group.attributes.push(event.option.value);
    console.log("selectedGroup attributes", this.group.attributes)
    // this.fruitInput.nativeElement.value = '';
    this.groupAttriCtrl.setValue(null);
    this.addGroupInQuery()
  }

  setGroupList(){
    console.log("this.attributesForGroup", this.attributesForGroup)
    this.attributesForGroup = this.groupAttriCtrl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value?.__id)),
      map(value => this._filterAttributesFilter(value))
    );
  }

  displayFn(attr) {
    return attr && attr.__id ? attr.__id : ''
  }
  filterAttrSelected(event: any, filter) {
    filter.attribute = event.option.value.__id;
    filter.dataType = event.option.value.dataType;
    console.log("filter event:", this.filter)
    this.addFilterInQuery()
  }

  addGroupInQuery(){
    let groupStr = "";
    console.log("this.group.attributes", this.group.attributes)
    this.group.attributes.forEach(element => {
      if(element.__id || element.name){
        if(groupStr) groupStr = groupStr + ",";
        groupStr = `${element.__id || element.name}`;
      }
    });
    this.customQuery.group = groupStr;
    this.inputMap['groupConfig'] = this.group;
    this.selectedInputOption['groupConfig'] = this.group;
    this.inputMap['query'] = this.customQuery;
    this.selectedInputOption['query'] = this.customQuery;
    this.inputParameter.emit(this.inputMap);
  }

  addFilterInQuery(){
    let filterStr = "";
    this.filter.filterItems.forEach(element => {
      if(element.attribute && element.value){
        if(filterStr) filterStr = filterStr + ",";
        filterStr = `${element.attribute}${element.operator || '='}${element.value}|${element.dataType || 'string'}`;
      }
    });
    this.customQuery.filter = filterStr;
    this.inputMap['filterConfig'] = this.filter;
    this.selectedInputOption['filterConfig'] = this.filter;
    this.inputMap['query'] = this.customQuery;
    this.selectedInputOption['query'] = this.customQuery;
    this.inputParameter.emit(this.inputMap);
  }

  private _filterAttributesFilter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    let filterables: any[] = this.boxObjectAttributes.filter((attr: any) => attr) //.filterable
    return filterables.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  filterToggleChanged(toggleValue: any){
    this.filter.filterEnabled = toggleValue.checked;
    if(!this.filter.filterItems || this.filter.filterItems.length == 0){
      this.addFilterItem();
    }
  }

  groupToggleChanged(toggleValue:any){
    this.group.groupEnabled = toggleValue.checked;
  }

  async getAttribute(){
    console.log("this.attributeInputMap", this.attributeInputMap);
    //write within a try catch block
    try {
      if(this.attributesFetched){
        this.boxObjectAttributes = this.attributesFetched;
      }
      else{
        this.boxObjectAttributes = await this.boxService.getAttributes(this.connection.box_id, this.action.__id, this.connection._id, undefined, this.attributeInputMap, this.boxObject)
      }
      console.log("ATTRIBUTES : ",this.boxObjectAttributes);
      this.getAttributesResponse.emit(this.boxObjectAttributes)
    } catch (e) {
      console.error("[ERROR] cant get Attribute : ", e);
      this.boxObjectAttributes = e;
      throw e;
    }

    this.show = true;
    this.enableExecute.emit(true);
    this.attributeHide = false;

  }

  async constructInputParams(){
    this.parameterWidgets = [];
    for (var i = 0; i < this.parameters?.list?.length; i++) {
      let paramMap:any = {};
      let widget;
      let listAttMap = this.parameters[this.parameters.list[i]];
      let widgetType:any = this.getWidgetType(listAttMap?.dataType);
      widget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget(widgetType.type)));
      widget.config.placeholder.value = listAttMap?.name || "Input";
      if (listAttMap?.required) widget['required'] = listAttMap?.required
      if (listAttMap?.notRequired) widget['notRequired'] = listAttMap?.notRequired
      if (listAttMap?.note) widget['note'] = listAttMap?.note
      if(this.existingInputs?.options?.[listAttMap?.__id]) widget.config.textContent.value = this.existingInputs?.options?.[listAttMap?.__id] || listAttMap?.defaultValue || "";
      paramMap.widget = widget;
      paramMap.key = listAttMap.__id || listAttMap.name;
      if(paramMap.widget){
        this.parameterWidgets.push(paramMap)
      }
    }
    if(this.parameterWidgets.length || this.attributesParameterWidgets.length) this.enableExecute.emit(true);
  }

  async constructParams(){
    console.log("inside construct params")
    console.log("DEPENDANCY MAP : ",JSON.parse(JSON.stringify(this.dependencyMap)))
    let paramList = this.prepareConstructParamList();
    console.log("PARAM LIST : ",paramList)
    if(this.action?.isSteppedInputRequired) this.inputMap["requiredInputFields"] = this.dependencyMap?.list
    this.spinner = true;
    if (this.dependencyMap.list.length > 0){
      //Stepped input Configuration is required
      for (let i = 0; i < paramList.length; i++) {
        let selectedAction = this.dependencyMap?.[paramList[i]]?.action || this.action
        let boxAttr = this.dependencyMap?.[paramList[i]]?.boxAttr
        let requiredInput = this.dependencyMap?.[paramList[i]]?.requiredInput
        let list = this.buildAttributeParams ? this.getAttributesFunction?.input?.options?.list || [] : selectedAction?.input?.list || [];
        if(requiredInput.length > 0){
          for(let j=0; j<requiredInput.length; j++){
            var isAllRequiredInputAvailable : boolean = false
            let id = requiredInput[j]
            //check if all requiredInput(id) has its value set
            if(this.dependencyMap[id]?.options) {
              isAllRequiredInputAvailable = true
            } else {
              isAllRequiredInputAvailable = false
              break
            }
          }
          if(!this.dependencyMap?.[paramList[i]]?.options){
            if(isAllRequiredInputAvailable) await this.buildParams(list, selectedAction, boxAttr)
          }
        } else {
          if(this.firsthit==false) continue;
          await this.buildParams(list, selectedAction, boxAttr)
        }
      }
    } else {
      await this.buildParams(paramList,this.action)
    }
    this.spinner = false;
  }

  async buildParams(list, selectedAction, boxAttr?){
    this.firsthit = false;
    console.log("inside build params",selectedAction)
    this.spinner = true
    console.log("isSupportQuery", this.isSupportQuery)
    console.log("existingValues", this.existingInputs)
    for (var i = 0; i < list.length; i++) {
      this.spinner = true
      console.log("list[i]", list[i])
      let paramMap:any = {};
      let subList = this.buildAttributeParams ? this.getAttributesFunction?.input?.options?.[list[i]]?.['list'] || [] : selectedAction?.input?.[list[i]]?.['list'] || [];
      // let subList = this.buildAttributeParams ? this.getAttributesFunction?.input?.[list[i]]?.['list'] || [] : selectedAction?.input?.[list[i]]?.['list'] || [];
      console.log("sublist", subList)

      if(subList &&  subList.length > 0){
        for (var j = 0; j < subList.length; j++) {
          let paramMap:any = {};
          let listAttMap = this.buildAttributeParams ? this.getAttributesFunction?.input?.options?.[list[i]][subList[j]] : selectedAction?.input?.[list[i]][subList[j]];
          // let listAttMap = this.buildAttributeParams ? this.getAttributesFunction?.input?.[list[i]][subList[j]] : selectedAction?.input?.[list[i]][subList[j]];
          console.log("listAttMap", listAttMap)
          if(listAttMap?.hidden == true) continue;
          if(listAttMap?.required == true && !selectedAction?.isSteppedInputRequired) {
            this.mandatoryInputs.push(subList[j])
            this.inputMap["requiredInputFields"] = this.mandatoryInputs;
          }
          let relationMeta = this.getAttributeKeyMeta(listAttMap, boxAttr);
          let boxRelationMeta = listAttMap.relation;
          console.log("relationMeta", relationMeta)
          console.log("boxRelationMeta", boxRelationMeta)
          paramMap.key = listAttMap.__id || listAttMap.name;
          paramMap.firstKey = list[i];
          if(relationMeta || boxRelationMeta){
            let valueOptions
            if(relationMeta){
              console.log("relationMeta")
              valueOptions = await this.runRelatedFunctions(relationMeta);
              console.log("VALUE OPTIONS : ",valueOptions)
              if(valueOptions.length < 1){
                let message = `No options for ${relationMeta.name} was found`
                this.valueOptionsObj.found = false
                this.valueOptionsObj.name = relationMeta.name
                this.valueOptionsObj.options = valueOptions
                this.valueOptionsObj.message = message
                // continue;
              } else {
                this.valueOptionsObj.found = true
                this.valueOptionsObj.name = relationMeta.name
                this.valueOptionsObj.options = valueOptions
                this.valueOptionsObj.message = ''
              }
            } else {
              console.log("boxRelationMeta")
              this.spinner = true
              valueOptions = await this.runRelatedBoxFunction(boxRelationMeta);
            }
            let valueTypeSelectionWidget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget('autocomplete')));

            // check if previously selected value exists in existingInputs
            if(this.existingInputs?.hasOwnProperty(paramMap.key)){
              // console.log("value available in top level", this.existingInputs[paramMap.key])
              // console.log("need to find and attach into", valueOptions)
            } else if(this.existingInputs?.options?.hasOwnProperty(paramMap.key)){
              // console.log("value available in nesting", this.existingInputs.options?.[paramMap.key])
              // console.log("need to find and attach into", valueOptions)
              valueOptions.forEach(opt => {
                if(opt.value == this.existingInputs?.options?.[paramMap.key]){
                  opt['default'] = true
                }
              })
              console.log("valueOptions final", valueOptions)
            }


            valueTypeSelectionWidget.config.availableOptions.staticOptions = valueOptions;
            valueTypeSelectionWidget.config.label.value = "Select " + listAttMap?.name || "Select Value";
            if (listAttMap?.required) valueTypeSelectionWidget['required'] = listAttMap?.required
            if (listAttMap?.notRequired) valueTypeSelectionWidget['notRequired'] = listAttMap?.notRequired
            if (listAttMap?.note) valueTypeSelectionWidget['note'] = listAttMap?.note
            paramMap.widget = valueTypeSelectionWidget;
          } else {
            let widgetType: any = this.getWidgetType(listAttMap?.dataType);
            let inputWidget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget(widgetType.type)));
            console.log("LIST ATT MAP", listAttMap);
            inputWidget.config.placeholder.value = listAttMap?.name || "Input";
            inputWidget.config[widgetType.setValueKey].value = this.existingInputs?.options?.[listAttMap?.__id] || listAttMap?.defaultValue || "";
            if (listAttMap?.required) inputWidget['required'] = listAttMap?.required
            if (listAttMap?.notRequired) inputWidget['notRequired'] = listAttMap?.notRequired
            if (listAttMap?.note) inputWidget['note'] = listAttMap?.note
            paramMap.widget = inputWidget;
          }
          this.buildAttributeParams ? this.attributesParameterWidgets.push(paramMap) : this.parameterWidgets.push(paramMap);

        }
      } else {
        console.log("else part", this.isBoxObject)
        if (this.isBoxObject || this.isOnlyOptions) continue;
        let listAttMap = this.buildAttributeParams ? this.getAttributesFunction?.input?.options?.[list[i]] : selectedAction?.input?.[list[i]];
        console.log("listAttrMap : ", listAttMap)
        if(listAttMap?.hidden == true) continue;
        console.log("-- list[i]", list[i])
        if(listAttMap?.required == true) {
          this.mandatoryInputs.push(list[i])
          this.inputMap["requiredInputFields"] = this.mandatoryInputs;
        }
        paramMap.key = listAttMap.__id || listAttMap.name;
        if(listAttMap.note) paramMap.note = listAttMap.note;
        let widget;
        if(((list[i] == "query" && !this.isSupportFilter) || list[i] == "data")  && this.isSupportQuery){
          // console.log("create widget for query, queryInputs", this.queryInputs)
          if(list[i] == 'query'){
            widget = {
              type: 'query',
              queryInputs: {
                filterConfig: this.queryInputs?.filterConfig,
                sortConfig: this.queryInputs?.sortConfig,
                groupConfig: this.queryInputs?.groupConfig,
                attributesConfig: this.queryInputs?.attributesConfig,
                limit: this.queryInputs?.limit,
              }
           }
           console.log("===> query widget created", widget)
          }else{
            widget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget('textarea')));
            widget.config.placeholder.value = list[i] == "data" ? "Data" : "Query";
            if (listAttMap?.required) widget['required'] = listAttMap?.required
            if (listAttMap?.notRequired) widget['notRequired'] = listAttMap?.notRequired
            if (listAttMap?.note) widget['note'] = listAttMap?.note
          }
        } else if(listAttMap.relation){
          console.log("relation block")
          let relationMeta = listAttMap.relation;
          this.spinner = true
          let valueOptions = await this.runRelatedBoxFunction(relationMeta);

          if(this.existingInputs?.hasOwnProperty(paramMap.key)){
            valueOptions.forEach(opt => {
              if(opt.value == this.existingInputs[paramMap.key]){
                opt['default'] = true
              }
            })
            console.log("valueOptions final", valueOptions)
          }else if(this.existingInputs?.options?.hasOwnProperty(paramMap.key)){
            // console.log("value available in nesting", this.existingInputs.options[paramMap.key])
            // console.log("need to find and attach into", valueOptions)
            valueOptions.forEach(opt => {
              if(opt.value == this.existingInputs.options[paramMap.key]){
                opt['default'] = true
              }
            })
            console.log("valueOptions final", valueOptions)
          }

          console.log("value options", valueOptions)
          widget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget('autocomplete')));
          widget.config.availableOptions.staticOptions = valueOptions;
          widget.config.label.value = "Select " + listAttMap?.name || "Select Value";
          if (listAttMap?.required) widget['required'] = listAttMap?.required
          if (listAttMap?.notRequired) widget['notRequired'] = listAttMap?.notRequired
          if (listAttMap?.note) widget['note'] = listAttMap?.note
        } else if(listAttMap.dataType == 'array') {
          let widgetsArr = [];
          let arrayListAttMap = listAttMap?.array || {};
          for (const ele of arrayListAttMap?.list) {
            if(arrayListAttMap[ele]?.list?.length > 0){
              let innerWidgetsArr = [];
              for (const innerElem of arrayListAttMap[ele].list){
                let widgetType: any = this.getWidgetType(listAttMap?.dataType);
                widget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget(widgetType.type)));
                widget.config.placeholder.value = arrayListAttMap[ele][innerElem]?.name || "Input";
                widget.config[widgetType.setValueKey].value = arrayListAttMap[ele][innerElem]?.defaultValue || "";
                if(arrayListAttMap[ele][innerElem]?.required) widget.required = arrayListAttMap[ele][innerElem]?.required
                innerWidgetsArr.push({firstKey:arrayListAttMap[ele]?.__id, key: arrayListAttMap[ele][innerElem]?.__id, widget});
              }
              let widgetsArrItemObj = {
                key: arrayListAttMap[ele]?.__id,
                name: arrayListAttMap[ele]?.name,
                widgets: innerWidgetsArr
              }
              if(arrayListAttMap[ele]?.notRequired?.length > 0) widgetsArrItemObj["notRequired"] = arrayListAttMap[ele]?.notRequired;
              widgetsArr.push(widgetsArrItemObj);
            } else {
              let widgetType: any = this.getWidgetType(listAttMap?.dataType);
              widget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget(widgetType.type)));
              widget.config.placeholder.value = arrayListAttMap[ele]?.name || "Input";
              widget.config[widgetType.setValueKey].value = arrayListAttMap[ele]?.defaultValue || "";
              if(arrayListAttMap[ele]?.dataType == 'divider'){
                widget.type = "divider"
              }
              let widgetsArrItemObj = {
                key: arrayListAttMap[ele]?.__id,
                name: arrayListAttMap[ele]?.name,
                widget
              }
              if(arrayListAttMap[ele]?.notRequired?.length > 0) widgetsArrItemObj["notRequired"] = arrayListAttMap[ele]?.notRequired;
              widgetsArr.push(widgetsArrItemObj);
            }
          }
          if (listAttMap?.notRequired) paramMap.notRequired = listAttMap?.notRequired
          if(this.existingInputs?.[paramMap.key]?.length) {
            this.existingInputs[paramMap.key].forEach(element => {
              this.addArrayInputs(widgetsArr, element);
            });
          }
          paramMap.widgets = widgetsArr;
          if(paramMap.widgets){
            this.buildAttributeParams ? this.attributesParameterWidgets.push(paramMap) : this.parameterWidgets.push(paramMap)
          }
          continue;
        } else if(list[i] != "options" && list[i] != "query") {
          let widgetType: any = this.getWidgetType(listAttMap?.dataType);
          widget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget(widgetType.type)));
          widget.config.placeholder.value = listAttMap?.name || "Input";
          widget.config[widgetType.setValueKey].value = this.existingInputs?.[listAttMap?.__id] || listAttMap?.defaultValue || "";
          if (listAttMap?.required) widget['required'] = listAttMap?.required
          if (listAttMap?.notRequired) widget['notRequired'] = listAttMap?.notRequired
          if (listAttMap?.note) widget['note'] = listAttMap?.note
        }

        paramMap.widget = widget;
        if(paramMap.widget){
          this.buildAttributeParams ? this.attributesParameterWidgets.push(paramMap) : this.parameterWidgets.push(paramMap)
        }
      }
    }
    this.spinner = false
    console.log("constructed widgets", this.parameterWidgets || this.attributesParameterWidgets)
    if(this.parameterWidgets.length || this.attributesParameterWidgets.length) this.enableExecute.emit(true);
  }

  async checkDependancy(selectedAction, boxAttr){
    console.log("Box Atttr : ",boxAttr)
    console.log("Selected Action : ",selectedAction)
    let list = selectedAction?.input?.list || [];
    for (var i = 0; i < list.length; i++) {
      let subList = selectedAction?.input?.[list[i]]?.['list'] || [];
      if(subList &&  subList.length > 0){
        for (var j = 0; j < subList.length; j++) {
          let listAttMap = selectedAction?.input?.[list[i]][subList[j]];
          let relationMeta = this.getAttributeKeyMeta(listAttMap, boxAttr);
          if(relationMeta){
            this.dependencyMap.list.push(relationMeta.__id)
            let obj = {
              boxAttr: boxAttr,
              action: selectedAction,
              listAttMap: listAttMap,
              relationMeta: relationMeta,
              requiredInput: []
            }
            this.dependencyMap[relationMeta.__id] = obj;
            let boxAttribute = await this.boxService.getAttributes(this.connection.box_id, `${relationMeta.relation.object}/get`, this.connection._id)
            let action = this.allActions?.find((e) => { return e.__id == `${relationMeta.relation.object}/get`});
            if(action) await this.checkDependancy(action,boxAttribute)
          }
        }
      }
    }
  }

  prepareConstructParamList(){
    var list = []
    if(this.dependencyMap.list.length > 0){
      list = this.dependencyMap.list
    } else {
      list = this.buildAttributeParams ? this.getAttributesFunction?.input?.options?.list || [] : this.action?.input?.list || [];
      // list = this.buildAttributeParams ? this.getAttributesFunction?.input?.list || [] : this.action?.input?.list || [];
    }
    return list
  }

  async runRelatedBoxFunction(relationMeta){
    this.spinner = true
    console.log("runRelatedBoxFunction",relationMeta)
    if(relationMeta.function == "getattributes" && this.attributesFetched){
      console.log("related fn is getAttributes")
      let valueOptions = this.constructOptions(this.attributesFetched, relationMeta);
      return valueOptions
    }
    let payload = {
      parameters: {}
    }

    let fun = await this.boxService.getAction(this.connection.box_id, relationMeta.function, this.connection._id, null, this.connection.boxToken)
    console.log("FUNCTION FETCHED", fun)
    console.log("this.optionalInputs", this.optionalInputs)
    let relationInputParams: any = {}
    fun?.input?.list?.forEach(op => {
      console.log("param", op)
      if(fun.input[op]?.list?.length){
        console.log("contains inner list")
        fun.input[op].list.forEach(innerOp => {
          let value = this.optionalInputs?.options[innerOp] || this.optionalInputs?.[innerOp] || fun.input[op]?.[innerOp]?.defaultValue;
          if(value){
            console.log("value available", value)
            if(!payload.parameters[op]) {
              payload.parameters[op] = {}
              console.log("created empty object", payload)
              console.log("op", op, "innerOp", innerOp)
            }
            let targetObject = payload.parameters[op]
            let sourceObject = {}
            sourceObject[innerOp] = value
            Object.assign(targetObject, sourceObject)
            // targetObject[innerOp] = this.optionalInputs.options[innerOp] || this.optionalInputs[innerOp]
            console.log("assigned inner param", JSON.parse(JSON.stringify(payload)))
          }
        })
      }else{
        console.log("top level param")
        if(this.optionalInputs?.options[op] || this.optionalInputs?.[op]){
          payload.parameters[op] = this.optionalInputs.options[op] || this.optionalInputs[op]
        }
      }
    })
    // console.log("relationInputParams", relationInputParams)
    // payload.parameters['options'] = relationInputParams

    let oRes = await this.boxService.executeBoxObjectFunction(this.connection._id, this.connection.box_id, `${relationMeta.function}`, payload, this.connection.boxToken)
    console.log("oRes", oRes)
    let valueOptions = this.constructOptions(oRes.data || oRes, relationMeta);
    this.spinner = false
    return valueOptions;
  }

  async runRelatedFunctions(relationMeta){
    this.spinner = true
    let object = relationMeta.relation.object;
    let payload = {
          parameters: {
            query: {
              page: "1|100|1",
            },
            options: this.selectedInputOption?.options || {}
          }
    }
    console.log("[PAYLOAD] : ",JSON.stringify(payload))
    let oRes = await this.boxService.executeBoxObjectFunction(this.connection._id, this.connection.box_id, `${object}/get`, payload)
    console.log("oRes", oRes)
    let valueOptions = this.constructOptions(oRes.data, relationMeta.relation);
    this.spinner = false;
    return valueOptions;
  }

  constructOptions(data, map){
    console.log("map", map)
    let result = [];
    for(var i = 0; i < data.length; i++){
      let object: any = {}
      object.value = this.getPropertyValue(data[i], map.attribute);
      object.name = this.getPropertyValue(data[i], map.name);
      result.push(object);
    };
    return result;
  }

  getPropertyValue(obj, propertyString: string) {
    const properties = propertyString.split('.');
    let value = obj;
    for (const property of properties) {
        value = value[property];
        if (value === undefined) return undefined;
    }
    return value;
}

  getAttributeKeyMeta(listAttMap, boxAttr?){
    let result: any = null;
    var boxObjectAttributes = boxAttr ? boxAttr : this.boxObjectAttributes
    let attributes = boxObjectAttributes?.filter((e) => { return e.__id == listAttMap.__id});
    console.log("attributes", attributes)
    if(attributes?.length > 0){
      let attribute = attributes[0];
      if(attribute.relation)result = attribute;
    }
    return result;
  }

  inputParameterwidgetsRecevied(inputMap: any){
    this.inputMap = inputMap;
    this.inputParameter.emit(this.inputMap);
  }

  addArrayInputs(param: any, value?: any, parentKey?:string, ) {
    if(value) {
      let id = value?._id
      for (const elem of param){
        if(elem?.key == id){
          param = elem;
          break;
        }
      }
      if(this.existingInputs?.root){
        if(!this.inputMap?.root) {
          this.inputMap["root"] = this.existingInputs.root;
          this.inputMap[this.existingInputs.root] = this.existingInputs[this.existingInputs.root]
        }
      }
    }
    if(param?.notRequired?.length > 0){
      for(const key of param.notRequired){
        for(const paramWidget of  this.parameterWidgets){
          if(paramWidget.key == key){
            if(paramWidget?.widget?.required) paramWidget.widget.required = false;
            if(this.mandatoryInputs.length > 0) {
              if(this.mandatoryInputs.includes(paramWidget.key)) {
                this.mandatoryInputs.splice(this.mandatoryInputs.indexOf(paramWidget.key),1)
                if(this.inputMap?.requiredInputFields) this.inputMap.requiredInputFields = this.mandatoryInputs;
              }
            }
          }
        }
      }
    }
    if (parentKey && !this.inputMap[parentKey]) {
      this.inputMap[parentKey] = [];
      this.inputMap["root"] = parentKey
    }
    var widgets = param.widget || param.widgets;
    var paramKey = param.key;
    if(param.widgets){
      let newWidgetParam = {key: paramKey, widgets:[]}
      if(param?.notRequired?.length > 0) newWidgetParam["notRequired"] = param.notRequired;
      for (const ele of widgets){
        if(ele?.widget?.required) {
          this.mandatoryInputs.push(ele?.key);
          if(this.inputMap?.requiredInputFields) this.inputMap.requiredInputFields = this.mandatoryInputs;
        }
        let widget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget('input')));
        widget.config.placeholder.value = ele?.widget?.config?.placeholder?.value || "Input";
        widget.config.textContent.value = value ? value?.[ele?.key] : ele?.widget?.config?.textContent?.value || "";
        if(ele?.widget?.required) widget.required = ele?.widget?.required
        newWidgetParam.widgets.push({firstKey: paramKey, key: ele.key, widget});
      }
      this.arrayParamWidgets.push(newWidgetParam);
    }
    if (param.widget){
      let newWidgetParam = {key: paramKey, widget:{}}
      let widget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget('input')));
      widget.config.placeholder.value = param?.widget?.config?.placeholder?.value || "Input";
      widget.config.textContent.value = param?.widget?.config?.textContent?.value || "";
      if(widgets?.type == 'divider'){
        widget.type = "divider"
        if(parentKey) this.inputMap[parentKey].push({_id: paramKey, divider:true})
        if(param?.notRequired?.length > 0) newWidgetParam["notRequired"] = param.notRequired;
        this.arrayParamWidgets.push({key: paramKey, widgets:[{key: paramKey, widget: widget}]});
      } else {
        newWidgetParam.widget = {key: paramKey, widget};
        if(param?.notRequired?.length > 0) newWidgetParam["notRequired"] = param.notRequired;
        this.arrayParamWidgets.push(newWidgetParam);
      }
    }
    if(!value) this.inputParameter.emit(this.inputMap);
  }

  removeArrayInputs(param: any, index: any) {
    var key = param.key
    if(this.inputMap[key]) this.inputMap[key].splice(index, 1);
    this.arrayParamWidgets.splice(index, 1);
    if (this.inputMap?.root){
      let root = this.inputMap.root
      if(this.inputMap[root]) this.inputMap[root].splice(index,1);
    }
    if(this.mandatoryInputs.length) {
      if(param?.widgets){
        for(const widget of param.widgets){
          if(this.mandatoryInputs.includes(widget.key)){
            this.mandatoryInputs.splice(this.mandatoryInputs.indexOf(widget.key),1);
            if(this.inputMap?.requiredInputFields) this.inputMap.requiredInputFields = this.mandatoryInputs;
          }
        }
      }
      if(param?.widget && this.mandatoryInputs.includes(key)){
        this.mandatoryInputs.splice(this.mandatoryInputs.indexOf(key),1);
        if(this.inputMap?.requiredInputFields) this.inputMap.requiredInputFields = this.mandatoryInputs;
      }
    }

    if(param?.notRequired?.length > 0){
      var count = 0
      let notReqList = param.notRequired;
      for(let widget of this.arrayParamWidgets){
        if (widget?.notRequired) {
          for (let ele of widget.notRequired){
            if(notReqList.includes(ele)) count++;
          }
        }
      }
      if(count == 0){
        for(const key of param.notRequired){
          for(const paramWidget of  this.parameterWidgets){
            if(paramWidget?.key == key){
              if(paramWidget?.widget?.required == false) paramWidget.widget.required = true;
              if(this.mandatoryInputs.length > 0) {
                if(!this.mandatoryInputs.includes(paramWidget.key)) {
                  this.mandatoryInputs.push(paramWidget.key)
                  if(this.inputMap?.requiredInputFields) this.inputMap.requiredInputFields = this.mandatoryInputs;
                }
              }
            }
          }
        }
      }
    }
    this.inputParameter.emit(this.inputMap);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.arrayParamWidgets, event.previousIndex, event.currentIndex);
    if(this.inputMap?.root && this.inputMap[this.inputMap?.root].length) moveItemInArray(this.inputMap[this.inputMap.root], event.previousIndex, event.currentIndex);
  }

  async ngAfterViewInit(){
    if(!this.isSupportQuery) this.inputParameter.emit(this.inputMap);
  }

  deleteByIndex(arr, index) {
    if (index < 0 || index >= arr.length) {
      return arr;
    }
    return arr.filter(function(_, i) {
      return i !== index;
    });
  }

  async valueReceived(event, param){
    this.selectedInputOption = {}
    console.log("event: ", event, "\nparam : ",param);

    // if auto selection is cleared it will also remove the dependant widget inputs, remove related options from inputMap and dependency map as well
    if(this.dependencyMap?.list?.length > 0 && !event.value){
      this.valueOptionsObj = {};
      let id = param?.widget?.id
      var paramWidget = this.parameterWidgets;
      for (var i = 0; i<paramWidget.length; i++){
        let widgetObj = paramWidget[i]
        if (id && (id == widgetObj?.["widget"]?.["id"])){
          var index = i;
          if(this.dependencyMap?.list.includes(paramWidget[i]?.key)){
            if(this.dependencyMap?.[paramWidget[i]?.key]?.options) delete this.dependencyMap?.[paramWidget[i]?.key]?.options;
            if(this.inputMap?.options?.[paramWidget[i]?.key]) delete this.inputMap?.options?.[paramWidget[i]?.key];
          }
          break;
        }
      }
      var widgets = paramWidget
      for (var j = index+1; j < paramWidget.length; j++) {
        if(this.dependencyMap?.list.includes(paramWidget[j]?.key)){
          if(this.dependencyMap?.[paramWidget[j]?.key]?.options) delete this.dependencyMap?.[paramWidget[j]?.key]?.options;
          if(this.inputMap?.options?.[paramWidget[j]?.key]) delete this.inputMap?.options?.[paramWidget[j]?.key];
          let pos = widgets.indexOf(paramWidget[j])
          widgets = this.deleteByIndex(widgets, pos);
        }
      }
      this.parameterWidgets = widgets;
    }

    console.log("valueReceived: inputMap", JSON.parse(JSON.stringify(this.inputMap)))
    if(param.firstKey){
      if(!this.inputMap[param.firstKey]) this.inputMap[param.firstKey] = {};
      if(!this.selectedInputOption[param.firstKey]) this.selectedInputOption[param.firstKey] = {};
      this.inputMap[param.firstKey][param.key] = event.value;
      this.selectedInputOption[param.firstKey][param.key] = event.value;
      if(this.dependencyMap[param.key]) this.dependencyMap[param.key][param.firstKey] = this.selectedInputOption[param.firstKey]
    } else {
      this.inputMap[param.key] = event.value;
      this.selectedInputOption[param.key] = event.value;
    }

    if(param?.widget?.type == 'query' && event.subComponentOutput){
      for (const key in event.subComponentOutput) {
        this.inputMap[key] = event.subComponentOutput[key]
        this.selectedInputOption[key] = event.subComponentOutput[key]
      }
      // this.inputMap.__limit = this.inputMap?.query?.page?.split('|')[1] || this.inputMap.__limit
    }

    console.log("this.selectedInputOption : ", JSON.stringify(this.selectedInputOption));
    if(this.dependencyMap?.list?.length > 0) this.inputMap['dependencyMap'] = this.dependencyMap;
    this.inputParameter.emit(this.inputMap);
    if(this.dependencyMap?.list?.length > 0 && event.value){
      await this.constructParams()
    }
  }

  attributeInputReceived(event, param){
    console.log("event i", event, param);

    if(param.firstKey){
      if(!this.attributeInputMap[param.firstKey]) this.attributeInputMap[param.firstKey] = {};
      this.attributeInputMap[param.firstKey][param.key] = event.value;
    } else {
      this.attributeInputMap[param.key] = event.value;
    }
    console.log("this.attributeInputMap", this.attributeInputMap);
    this.inputParameter.emit(this.attributeInputMap);
  }

  constructFieldMap(){
    let fieldObj:any = {};
    let list = [];

    if(this.fieldListingMap?.sourceFields?.length > 0) {
      list.push('sourceFields');
      let fieldObjsourceFields = this.getFieldMapObject('sourceFields','SOURCE FIELDS',this.fieldListingMap.sourceFields)
      fieldObj[fieldObjsourceFields.id] = fieldObjsourceFields
    }
    if(this.fieldListingMap?.systemFields?.length > 0) {
      list.push('systemFields');
      var fieldObjsystemFields = this.getFieldMapObject('systemFields','SYSTEM FIELDS',this.fieldListingMap.systemFields)
      fieldObj[fieldObjsystemFields.id] = fieldObjsystemFields
    }
    fieldObj.list = list;
    this.fieldMap = fieldObj;
    console.log("FIELD MAP PREPARED : ",this.fieldMap)
  }

  getFieldMapObject(_id:string, displayName:string, fields:any, option?:any){
    var obj = {
      id : _id,
      displayName : displayName,
      fields : fields,
      options : option ? option : {}
    }
    return obj
  }

  getWidgetType(dataType:string){
    if(!dataType) return {type: 'input', setValueKey: 'textContent'}
    switch(dataType){
      case 'date': return {type: 'date', setValueKey: 'datevalue'}
      case 'datetime': return {type: 'datetime', setValueKey: 'dateTimeValue'}
      //more data types will get added as cases
      default: return {type: 'input', setValueKey: 'textContent'}
    }
}

}
